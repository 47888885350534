@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track:hover {
  background-color: #f2f2f2;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

html {
  background: #f5f2ef;
}

@media (max-width: 720px) {
  pre {
    width: calc(100vw - 110px);
  }
}

pre:has(div.codeblock) {
  padding: 0;
}

/* Gradients for avatar images png */

/* CSS for a circular div with an opaque-to-transparent gradient */
.circular-gradient {
  border-radius: 70%;
  /* background: radial-gradient(circle, rgba(0, 0, 0, 1) 70%, transparent); */
}

@import url('https://fonts.cdnfonts.com/css/euclid-circular-b');
